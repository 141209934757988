import React from 'react'

import { FieldValues, useFormContext, Path, Controller } from 'react-hook-form'

import { LocationSelect, LocationSelectProps, ErrorMessage } from 'src/atoms'

export type RHFLocationSelectProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  onChange?: LocationSelectProps['onChange']
} & Omit<LocationSelectProps, 'value' | 'onChange'>

export const RHFLocationSelect = <TFieldValues extends FieldValues>({
  name,
  ...props
}: RHFLocationSelectProps<TFieldValues>) => {
  const { control } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <LocationSelect {...field} {...props} blurInputOnSelect />
            <ErrorMessage message={error?.message} />
          </>
        )
      }}
    />
  )
}

import { Button, Dialog, DialogDescription, DialogTitle } from 'src/atoms'

const DeleteDialog = ({ isOpen, deleteId, deleteDialogHandler }) => {
  const handleDelete = (deleteId, isDelete) => {
    deleteDialogHandler(deleteId, false, isDelete)
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          deleteDialogHandler(null, false, false)
        }}
        className="relative z-50"
      >
        <DialogTitle showCloseIcon={false}>
          Are you sure you want to delete this?
        </DialogTitle>
        <DialogDescription>
          Please note that this action cannot be undone.
        </DialogDescription>
        <div className="flex gap-4 mt-6 justify-end">
          <Button plain onClick={() => handleDelete(null, false)}>
            Cancel
          </Button>
          <Button color={'red'} onClick={() => handleDelete(deleteId, true)}>
            Delete
          </Button>
        </div>
      </Dialog>
    </>
  )
}

export default DeleteDialog

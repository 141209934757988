import {
  FindIndustries,
  FindIndustriesVariables,
  CreateIndustryMutation,
  CreateIndustryMutationVariables,
  DeleteIndustryMutation,
  DeleteCertificationMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Text } from 'src/atoms'
import { IndustrySchema } from 'src/schemas'

import IndustryForm from '../IndustryForm/IndustryForm'

export const QUERY: TypedDocumentNode<FindIndustries, FindIndustriesVariables> =
  gql`
    query FindIndustries {
      industries {
        id
        name
        createdAt
        updatedAt
        userId
      }
    }
  `

const DELETE_INDUSTRY_MUTATION = gql`
  mutation DeleteIndustryMutation($id: Int!) {
    deleteIndustry(id: $id) {
      id
    }
  }
`

const CREATE_INDUSTRY_MUTATION = gql`
  mutation CreateIndustryMutation($input: CreateIndustryInput!) {
    createIndustry(input: $input) {
      id
    }
  }
`

export const Loading = () => (
  <Text weight="semibold" className="text-sm text-gray-700">
    Loading...
  </Text>
)

export const Failure = ({ error }: CellFailureProps) => (
  <>{error && <div className="rw-cell-error">{error.message}</div>}</>
)

export const Success = ({
  industries,
}: CellSuccessProps<FindIndustries, FindIndustriesVariables>) => {
  const [createIndustry, { loading: createIndustryLoading }] = useMutation<
    CreateIndustryMutation,
    CreateIndustryMutationVariables
  >(CREATE_INDUSTRY_MUTATION, {
    // * We purposely don't show a success toast here because industries can be added
    // * in rapid succession from the suggestions and causes the toasts to take up
    // * a large amount of the screen.

    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const [deleteIndustry, { loading: deleteIndustryLoading }] = useMutation<
    DeleteIndustryMutation,
    DeleteCertificationMutationVariables
  >(DELETE_INDUSTRY_MUTATION, {
    onCompleted: () => {
      toast.success('Industry deleted')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const loading = createIndustryLoading || deleteIndustryLoading

  const handleSubmit = (input: IndustrySchema) => {
    createIndustry({ variables: { input } })
  }

  const handleDelete = (id: number) => {
    deleteIndustry({ variables: { id } })
  }

  return (
    <IndustryForm
      industries={industries}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      loading={loading}
    />
  )
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { cva } from 'class-variance-authority'
import clsx from 'clsx'
import debounce from 'debounce'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Select, {
  ActionMeta,
  components,
  ControlProps,
  GroupBase,
  InputProps,
  MenuProps,
  OptionProps,
  ContainerProps as SelectContainerProps,
  Props as SelectProps,
  SingleValue as SingleValueOptionType,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select'
import SelectBaseProps from 'react-select/base'

import { env } from 'src/lib/env'

import { reactSelectVariants } from '../reactSelectVariants'
import { Option as BaseOption } from '../types'

const googleMapsApiKey = env.googleMapsApiKey
const debounceWait = process.env.NODE_ENV === 'production' ? 128 : 256

type Option = BaseOption<string, string>

const controlVariants = cva(
  'flex items-center h-full justify-between relative w-full',
  {
    variants: {
      background: {
        white: 'bg-white',
        primary: 'bg-primary-300',
        transparent: '',
      },
      bordered: {
        true: 'border shadow-sm after:pointer-events-none after:absolute after:rounded-lg after:-inset-[1px] after:ring-inset after:focus-within:ring-2 after:focus-within:ring-primary-700',
        false: '',
      },
      rounded: {
        true: 'rounded-lg',
        false: '',
      },
      isFocused: {
        true: '',
        false: '',
      },
      isDisabled: {
        true: '!bg-gray-50 !border-gray-100',
        false: 'cursor-text',
      },
      size: {
        sm: 'min-h-7',
        lg: 'min-h-9',
      },
    },
    compoundVariants: [
      {
        bordered: true,
        background: 'white',
        className: 'border-gray-500',
      },
      {
        bordered: true,
        background: 'primary',
        className: 'border-primary-700',
      },
    ],
  }
)

const Control = ({
  className,
  children,
  ...props
}: ControlProps<Option, false, GroupBase<Option>>) => (
  <components.Control
    className={clsx(
      controlVariants({
        background: props.selectProps.background,
        bordered: props.selectProps.bordered,
        rounded: props.selectProps.rounded,
        isFocused: props.isFocused,
        isDisabled: props.isDisabled,
        size: props.selectProps.size,
      }),
      className
    )}
    {...props}
  >
    {props.selectProps.startIcon}
    {children}
  </components.Control>
)

const DropdownIndicator = () => null

const IndicatorSeparator = () => null

const Input = ({
  className,
  ...props
}: InputProps<Option, false, GroupBase<Option>>) => (
  <components.Input className={clsx('px-3', className)} {...props} />
)

const Menu = ({
  className,
  ...props
}: MenuProps<Option, false, GroupBase<Option>>) =>
  props.options.length ? (
    <components.Menu
      className={clsx(
        'absolute top-full z-10 my-1 w-full rounded-lg bg-white drop-shadow-xl',
        className
      )}
      {...props}
    />
  ) : null

const optionVariants = cva(
  'min-h-9 py-2 px-3 select-none hover:bg-primary-200 !flex items-center w-full',
  {
    variants: {
      isFocused: {
        true: 'bg-primary-200',
        false: '',
      },
      isSelected: {
        true: '!bg-primary-700 text-white',
        false: '',
      },
    },
  }
)

const Option = ({
  className,
  ...props
}: OptionProps<Option, false, GroupBase<Option>>) => (
  <components.Option
    className={clsx(
      optionVariants({
        isFocused: props.isFocused,
        isSelected: props.isSelected,
      }),
      className
    )}
    {...props}
  />
)

const selectContainerVariants = cva('w-full relative', {
  variants: {
    size: {
      sm: 'font-semibold text-sm min-h-7',
      lg: 'font-medium text-md min-h-9',
    },
    isDisabled: {
      true: 'text-gray-700 !pointer-events-auto cursor-not-allowed',
      false: 'text-gray-950',
    },
  },
})

const SelectContainer = ({
  className,
  ...props
}: SelectContainerProps<Option, false, GroupBase<Option>>) => (
  <components.SelectContainer
    className={clsx(
      selectContainerVariants({
        size: props.selectProps.size,
        isDisabled: props.isDisabled,
      }),
      className
    )}
    {...props}
  />
)

const SingleValue = ({
  className,
  ...props
}: SingleValueProps<Option, false, GroupBase<Option>>) => (
  <components.SingleValue className={clsx('px-3', className)} {...props} />
)

const ValueContainer = ({
  className,
  ...props
}: ValueContainerProps<Option, false, GroupBase<Option>>) => (
  <components.ValueContainer className={clsx('gap-2', className)} {...props} />
)

export type LocationSelectProps = Omit<
  SelectProps<Option, false, GroupBase<Option>>,
  'onChange' | 'value' | 'options'
> & {
  value?: string
  onChange: (value?: string) => void
  types?: string[]
}

export const LocationSelect = React.forwardRef(
  (
    {
      name,
      startIcon,
      value,
      onChange,
      styles,
      classNames,
      types,
      className,
      isMulti,
      components,
      // custom props
      rounded = true,
      bordered = true,
      background = 'white',
      size = 'lg',
      ...props
    }: LocationSelectProps,
    ref: React.Ref<SelectBaseProps<Option, false, GroupBase<Option>>>
  ) => {
    const [options, setOptions] = useState<Option[]>([])

    const { placePredictions, getPlacePredictions } = usePlacesService({
      apiKey: googleMapsApiKey,
    })

    const handleInputChange = useMemo(
      () =>
        debounce((newValue: string) => {
          getPlacePredictions({
            input: newValue,
            types,
            componentRestrictions: { country: 'us' },
          })
        }, debounceWait),
      [getPlacePredictions, types]
    )

    const handleChange = useCallback(
      (
        newOption: SingleValueOptionType<Option>,
        actionMetadata: ActionMeta<Option>
      ) => {
        if (actionMetadata.action === 'clear') {
          setOptions([])
          return onChange()
        }
        if (actionMetadata.action === 'select-option' && newOption) {
          onChange(newOption.value)
        }
      },
      [onChange]
    )

    useEffect(() => {
      const newOptions: Option[] =
        placePredictions?.map?.((prediction) => ({
          label: prediction?.description,
          value: prediction?.description,
        })) || []

      let optionsHaveChanged = false
      const existingLabels = options.map((option) => option.label)
      newOptions.forEach((option) => {
        if (!existingLabels.includes(option.label)) {
          optionsHaveChanged = true
        }
      })
      if (optionsHaveChanged) {
        setOptions(newOptions)
      }
    }, [placePredictions, options])

    const previousValueRef = useRef<string | undefined>()
    useEffect(() => {
      if (!value || previousValueRef.current === value) return
      previousValueRef.current = value
      const selectedOption = options.find((option) => option.value === value)
      // Anytime we don't find an option, create it and select it
      // this enables passing initial/default values to this component
      if (!selectedOption) {
        setOptions([{ value, label: value }])
      }
    }, [options, value])

    const selectedOption = useMemo(() => {
      return options.find((option) => option.value === value)
    }, [options, value])

    return (
      <Select<Option, false>
        {...props}
        ref={ref}
        className={clsx(
          // this doesn't apply any classnames but can potentially
          reactSelectVariants({
            background,
            bordered,
            rounded,
            size,
          }),
          className
        )}
        name={name}
        isSearchable
        isMulti={isMulti}
        unstyled
        onInputChange={handleInputChange}
        onChange={handleChange}
        options={options}
        value={selectedOption}
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator,
          Input,
          Menu,
          Option,
          SelectContainer,
          SingleValue,
          ValueContainer,
          ...components,
        }}
        styles={{
          control: () => ({}),
          option: () => ({}),
          ...styles,
        }}
        classNames={{
          placeholder: () => 'text-gray-700 px-3',
          noOptionsMessage: () => 'hidden',
          indicatorsContainer: () => 'me-1.5',
          ...classNames,
        }}
        // custom props
        startIcon={startIcon}
        background={background}
        bordered={bordered}
        rounded={rounded}
        size={size}
      />
    )
  }
)

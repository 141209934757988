import { useRef } from 'react'

import { BriefcaseIcon } from '@heroicons/react/24/solid'

import {
  BadgeButton,
  BadgeColor,
  BadgeSize,
  Button,
  Card,
  Text,
} from 'src/atoms'

type TopStartingRole = {
  title: string
  numberOfJobs: number
  compensationMax: number
}

// Subset of popular roles from our full set.
const desiredPositions = [
  'CNC machinist',
  'Assembler',
  'Welder',
  'Machinist',
  'Packaging worker',
  'Machine operator',
  'Line operator',
  'Quality technician',
  'Tool and die maker',
  'Production technician',
  'Fabricator',
  'Maintenance technician',
  'Project manager',
  'Forklift operator',
  'CNC programmer',
  'Material handler',
  'Quality inspector',
  'Production supervisor',
  'Manufacturing engineer',
]

const topStartingRoles: TopStartingRole[] = [
  {
    title: 'Forklift operator',
    numberOfJobs: 4000,
    compensationMax: 27,
  },
  {
    title: 'Production technician',
    numberOfJobs: 3000,
    compensationMax: 25,
  },
  {
    title: 'Assembler',
    numberOfJobs: 6000,
    compensationMax: 25,
  },
  {
    title: 'Machinist',
    numberOfJobs: 10000,
    compensationMax: 63,
  },
]

export function DesiredPositionStep({
  setDesiredPosition,
}: {
  setDesiredPosition: (
    desiredPosition: string,
    isTopStartingRole: boolean
  ) => void
}) {
  const [showTopStartingRoles, setShowTopStartingRoles] = React.useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const toggleShowTopStartingRoles = () => {
    setShowTopStartingRoles(!showTopStartingRoles)
    // Attempt to scroll (mostly) to top of view.
    containerRef.current?.scrollIntoView()
  }

  const titleText = showTopStartingRoles
    ? 'Explore our top starting roles'
    : 'What type of work are you looking for?'
  const descriptionText = showTopStartingRoles
    ? "We've put together a list of high-paying, in-demand roles. You can update it anytime, so choose the one that interests you."
    : 'You can change this at anytime.'
  const buttonText = showTopStartingRoles
    ? 'Back'
    : 'Undecided? Try these jobs to start'

  return (
    <div className="flex flex-col gap-6" ref={containerRef}>
      <div>
        <Text weight="bold" className="text-2xl">
          {titleText}
        </Text>
        <Text className="text-md text-gray-900">{descriptionText}</Text>
      </div>
      {showTopStartingRoles ? (
        <>
          {topStartingRoles.map((topStartingRole) => {
            return (
              <Card
                className="flex flex-col gap-4 !bg-gray-100 !rounded-md"
                key={topStartingRole.title}
              >
                <div className="flex items-center justify-between">
                  <div className="flex gap-2">
                    <BriefcaseIcon className="text-primary-700 h-4 h-4 shrink-0 mt-[2px]" />
                    <Text weight="semibold" className="text-md">
                      {topStartingRole.title}
                    </Text>
                  </div>
                  <Button
                    onClick={() =>
                      setDesiredPosition(topStartingRole.title, true)
                    }
                  >
                    Select
                  </Button>
                </div>
                <div className="bg-white rounded-md p-2">
                  <ul className="ml-6 list-disc">
                    <li>
                      <Text className="text-md">
                        <span className="font-bold">
                          {topStartingRole.numberOfJobs.toLocaleString()}
                        </span>{' '}
                        jobs on Laborup
                      </Text>
                    </li>
                    <li>
                      <Text className="text-md">
                        Jobs up to{' '}
                        <span className="font-bold">
                          ${topStartingRole.compensationMax}
                        </span>
                      </Text>
                    </li>
                  </ul>
                </div>
              </Card>
            )
          })}
        </>
      ) : (
        <div className="flex flex-wrap gap-3">
          {desiredPositions.map((desiredPosition) => {
            return (
              <BadgeButton
                key={desiredPosition}
                badgeClasses="!px-3 !py-2"
                color={BadgeColor.gray}
                onClick={() => setDesiredPosition(desiredPosition, false)}
                size={BadgeSize.md}
              >
                {desiredPosition}
              </BadgeButton>
            )
          })}
        </div>
      )}
      {/* Hack to ensure sticky buttons don't cover up content on bottom. */}
      <div className="h-12" />
      <div className="fixed bottom-0 left-0 border-t border-gray-200 px-8 py-4 bg-white w-full">
        <Button className="w-full" onClick={toggleShowTopStartingRoles} outline>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

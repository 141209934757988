import { toast } from '@redwoodjs/web/toast'

import { Button } from './Button'
import { ButtonProps } from './types'

type CopyTextButtonProps = ButtonProps & {
  text: string
  successMessage?: string
}

export function CopyTextButton({
  // Prevents the `onClick` prop from being passed to `Button`.
  // TODO: A better solution might be to prevent `onClick` from being passed to
  // `CopyLinkButton` in the first place but I was running into type errors when
  // trying to use `Omit` to accomplish this...
  onClick: _onClick,
  text,
  successMessage = 'Copied to clipboard!',
  ...props
}: CopyTextButtonProps) {
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(text)
      toast.success(successMessage)
    } catch {
      toast.error('Failed to copy to clipboard!')
    }
  }

  return <Button {...props} onClick={copyLink} />
}

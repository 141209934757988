import { useState, useRef, useEffect, useCallback } from 'react'

import { BoltIcon } from '@heroicons/react/24/solid'
import { FindIndustries } from 'types/graphql'

import {
  BadgeButton,
  BadgeColor,
  Button,
  Field,
  FieldGroup,
  Fieldset,
  Input,
  PlusIcon,
  Text,
  XMarkIcon,
} from 'src/atoms'
import { RecommendedIndustry } from 'src/lib/constants'
import { initialRecommendedIndustries } from 'src/lib/constants'
import { IndustrySchema } from 'src/schemas'

const prepareInitialRecommendedIndustries = (
  industries: FindIndustries['industries']
): RecommendedIndustry[] => {
  const industriesSet = new Set(industries.map(({ name }) => name))
  return initialRecommendedIndustries.map((recommendedIndustry) => {
    return {
      ...recommendedIndustry,
      added: industriesSet.has(recommendedIndustry.name),
    }
  })
}

const IndustryForm = ({
  industries,
  onDelete,
  onSubmit,
  loading,
}: {
  industries: FindIndustries['industries']
  onDelete: (id: number) => void
  onSubmit: (input: IndustrySchema) => void
  loading: boolean
}) => {
  const [recommendedIndustries, setRecommendedIndustries] = useState<
    RecommendedIndustry[]
  >([])

  const numberAvailableRecommendedIndustries = recommendedIndustries.filter(
    (recommendedIndustries) => !recommendedIndustries.added
  ).length

  const handleDelete = useCallback(
    (name: string) => {
      const industryToDelete = industries.find(
        (industry) => industry.name === name
      )
      if (!industryToDelete) return
      onDelete(industryToDelete.id)
      setRecommendedIndustries(
        recommendedIndustries.map((industry) => {
          if (industry.name === name) {
            return { ...industry, added: false }
          }
          return { ...industry }
        })
      )
    },
    [industries, onDelete, recommendedIndustries]
  )

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleAddNewIndustry = useCallback(
    (name: string) => {
      if (!name || industries.find((industry) => industry.name === name)) return

      onSubmit({ name })

      setRecommendedIndustries(
        recommendedIndustries.map((recommendedIndustry) => {
          if (recommendedIndustry.name === name) {
            return { ...recommendedIndustry, added: true }
          }
          return recommendedIndustry
        })
      )
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    },
    [industries, onSubmit, recommendedIndustries]
  )

  useEffect(() => {
    setRecommendedIndustries(prepareInitialRecommendedIndustries(industries))
  }, [industries])

  return (
    <form>
      <FieldGroup>
        <Fieldset>
          <Field className="mb-4 flex flex-row flex-nowrap items-end">
            <div className="me-2 flex flex-1 flex-col">
              <Input
                ref={inputRef}
                placeholder="Enter Industry"
                className=""
                onKeyDown={(e) => {
                  if (e.key !== 'Enter') {
                    return
                  }
                  e.preventDefault()
                  if (inputRef.current?.value) {
                    handleAddNewIndustry(inputRef.current.value)
                  }
                }}
              />
            </div>
            <Button
              size="lg"
              disabled={loading}
              onClick={() => {
                if (inputRef.current?.value) {
                  handleAddNewIndustry(inputRef.current.value)
                }
              }}
            >
              <PlusIcon />
              Add
            </Button>
          </Field>
          <div className="d-flex mt-n2 ms-n2 flex-wrap">
            {industries.map((industry, indx) => (
              <BadgeButton
                key={indx}
                className="fs-sm mb-1 me-1 ms-2 mt-2"
                onClick={() => {
                  if (industry.name) {
                    handleDelete(industry.name)
                  }
                }}
              >
                {industry.name}
                <XMarkIcon className="h-4 w-4 text-gray-950" />
              </BadgeButton>
            ))}
          </div>
        </Fieldset>
        {numberAvailableRecommendedIndustries > 0 && (
          <div className="rounded border border-gray-200 bg-gray-50 px-4 py-3 md:px-6 md:py-4">
            <div className="mb-2.5 flex items-center">
              <Text weight="semibold" className="text-md text-gray-950">
                <span className="flex items-center">
                  <BoltIcon className="text-primary-700 me-1.5 h-5 w-5 shrink-0" />
                  Laborup Suggestions
                </span>
              </Text>
            </div>
            <div className="d-flex flex-wrap gap-3">
              {recommendedIndustries.map((recommendedIndustry) => {
                if (recommendedIndustry.added) {
                  return null
                }

                return (
                  <BadgeButton
                    key={recommendedIndustry.id}
                    color={BadgeColor.gray}
                    disabled={loading}
                    onClick={() =>
                      handleAddNewIndustry(recommendedIndustry.name)
                    }
                  >
                    {recommendedIndustry.name}
                    <PlusIcon className="h-4 w-4 text-gray-950" />
                  </BadgeButton>
                )
              })}
            </div>
          </div>
        )}
      </FieldGroup>
    </form>
  )
}

export default IndustryForm

import React, { useMemo, forwardRef } from 'react'

import {
  SelectFormikDeprecated,
  SelectFormikDeprecatedProps,
} from './SelectFormikDeprecated'

const DEFAULT_START_YEAR = 1950
const DEFAULT_END_YEAR = new Date().getFullYear()

/**
 * @deprecated These props are deprecated and will be removed
 */
export type SelectYearDeprecatedProps = SelectFormikDeprecatedProps & {
  endYear?: number
}
/**
 * @deprecated This component is deprecated and will be removed, prefer to use
 * Select from atoms or react hook form folder instead
 */
export const SelectYearDeprecated = forwardRef<
  HTMLSelectElement,
  SelectYearDeprecatedProps
>(function SelectYear({ endYear = DEFAULT_END_YEAR, ...props }, ref) {
  const yearOptions = useMemo(() => {
    return Array.from({ length: endYear - DEFAULT_START_YEAR + 1 }, (_, i) => ({
      label: (endYear - i).toString(),
      value: (endYear - i).toString(),
    }))
  }, [endYear])

  return (
    <SelectFormikDeprecated ref={ref} {...props}>
      {yearOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectFormikDeprecated>
  )
})

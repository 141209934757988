import { useCallback, useEffect, useState } from 'react'

import Mixpanel from 'mixpanel-browser'

import { Dialog, DisjointProgressBar } from 'src/atoms'

import { DesiredPositionStep } from './DesiredPositionStep/DesiredPositionStep'
import { IndustriesStep } from './IndustriesStep/IndustriesStep'
import { SkillsStep } from './SkillsStep/SkillsStep'
import EmploymentStatusStepCell from './EmploymentStatusStepCell/'

type State =
  | {
      currentStepIndex: 0
    }
  | {
      currentStepIndex: 1
      employmentStatusId: number
    }
  | {
      currentStepIndex: 2
      employmentStatusId: number
      desiredPosition: string
      isTopStartingRole: boolean
    }
  | {
      currentStepIndex: 3
      employmentStatusId: number
      desiredPosition: string
      isTopStartingRole: boolean
    }

export function DefaultOnboardingDialog() {
  const [state, setState] = useState<State>({
    currentStepIndex: 0,
  })

  const trackStepEvent = useCallback((step: number) => {
    if (sessionStorage.getItem(`onboarding_step${step}`)) {
      Mixpanel.track(`user_revisiting_onboarding_step_${step}`)
    } else {
      sessionStorage.setItem(`onboarding_step${step}`, 'true')
      Mixpanel.track(`onboarding_step_${step}`)
    }
  }, [])

  // Send Mixpanel event for step 0 when the component initially renders.
  useEffect(() => {
    trackStepEvent(0)
  }, [trackStepEvent])

  const handleSetState = (newState: State) => {
    // Send Mixpanel event for the step the user is going to.
    trackStepEvent(newState.currentStepIndex)

    setState(newState)
  }

  return (
    <Dialog
      className="p-6"
      onClose={() => {}} // This prevents the dialog from being manually closed.
      size="fullScreen"
      open
    >
      <div className="flex flex-col gap-4">
        <DisjointProgressBar
          currentStep={state.currentStepIndex}
          totalSteps={4}
        />
        {state.currentStepIndex === 0 && (
          <EmploymentStatusStepCell
            setEmploymentStatusId={(employmentStatusId: number) => {
              handleSetState({
                currentStepIndex: 1,
                employmentStatusId,
              })
            }}
          />
        )}
        {state.currentStepIndex === 1 && (
          <DesiredPositionStep
            setDesiredPosition={(
              desiredPosition: string,
              isTopStartingRole: boolean
            ) => {
              handleSetState({
                ...state,
                currentStepIndex: 2,
                desiredPosition,
                isTopStartingRole,
              })
            }}
          />
        )}
        {state.currentStepIndex === 2 && (
          <SkillsStep
            desiredPosition={state.desiredPosition}
            shouldOnlyRecommendSoftSkills={state.isTopStartingRole}
            handleBack={() => {
              handleSetState({
                ...state,
                currentStepIndex: 1,
              })
            }}
            handleNext={() => {
              handleSetState({
                ...state,
                currentStepIndex: 3,
              })
            }}
          />
        )}
        {state.currentStepIndex === 3 && (
          <IndustriesStep
            employmentStatusId={state.employmentStatusId}
            desiredPosition={state.desiredPosition}
            isTopStartingRole={state.isTopStartingRole}
            handleBack={() => {
              handleSetState({
                ...state,
                currentStepIndex: 2,
              })
            }}
          />
        )}
      </div>
    </Dialog>
  )
}

import { forwardRef } from 'react'

import {
  Select as HeadlessSelect,
  type SelectProps as HeadlessSelectProps,
} from '@headlessui/react'
import { clsx } from 'clsx'

import { ChevronDownIcon, XMarkIcon } from 'src/atoms'

/**
 * @deprecated These props are deprecated and will be removed
 */
export type SelectFormikDeprecatedProps = HeadlessSelectProps & {
  // Formik specific prop, avoid sending to atoms
  setFieldValue: (name: string, value: any) => void
  isClearable?: boolean
}

/**
 * @deprecated This component is deprecated and will be removed, prefer to use
 * Select from atoms or react hook form folder instead
 */
export const SelectFormikDeprecated = forwardRef<
  HTMLSelectElement,
  SelectFormikDeprecatedProps
>(function Select(
  { className, isClearable = false, setFieldValue, ...props },
  ref
) {
  return (
    <span
      data-slot="control"
      className={clsx([
        className,
        // Basic layout
        'group relative block w-full',
        // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode

        'before:shadow before:absolute before:inset-px before:rounded-lg before:bg-white',
        // Focus ring
        'sm:after:has-[[data-focus]]:ring-primary-700 after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:has-[[data-focus]]:ring-2',
        // Disabled state
        'before:has-[[data-disabled]]:bg-gray-200',
        'before:has-[[data-disabled]]:shadow-none before:pointer-events-none',
      ])}
    >
      <HeadlessSelect
        ref={ref}
        onChange={(e) => setFieldValue(props.name, e.target.value)}
        {...props}
        multiple={false}
        className={clsx([
          // Basic layout
          'relative block w-full min-h-9 appearance-none rounded-lg py-1 bg-transparent',

          // Horizontal padding
          'pl-[calc(theme(spacing[3.5])-1px)] pr-[calc(theme(spacing.10)-1px)] sm:pl-[calc(theme(spacing.3)-1px)] sm:pr-[calc(theme(spacing.9)-1px)]',

          // Options (multi-select)
          '[&_optgroup]:font-semibold',

          // Typography
          'text-md font-medium',

          // Border
          'border border-gray-500',

          // Hide default focus styles
          'focus:outline-none',

          // Invalid state
          'data-[invalid]:border-red-700 data-[invalid]:data-[hover]:border-red-800',

          // Disabled state
          'data-[disabled]:border-gray-400 data-[disabled]:cursor-not-allowed data-[disabled]:text-gray-700',

          // default color and placeholder color
          props.value ? 'text-gray-950' : 'text-gray-700',
        ])}
      >
        <>
          <option value="" disabled hidden selected>
            {props.placeholder}
          </option>
          {props.children}
        </>
      </HeadlessSelect>
      {isClearable && props.value ? (
        <button
          className="absolute inset-y-0 right-0 flex items-center pr-2"
          onClick={() => setFieldValue(props.name, '')}
        >
          <XMarkIcon className="h-5 w-5 text-gray-950" />
        </button>
      ) : (
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon className="h-5 w-5 text-gray-950" />
        </span>
      )}
    </span>
  )
})

import * as yup from 'yup'

export const workExperienceSchema = yup.object().shape({
  title: yup.string().required('Job title is required'),
  company: yup.string().required('Company name is required'),
  location: yup.string().required('Work location is required'),
  startMonth: yup.string().required('required'),
  startYear: yup.string().required('required'),
  endMonth: yup
    .string()
    .nullable()
    .when('currentPosition', {
      is: false,
      then: () => yup.string().required('required'),
    }),
  endYear: yup.string().when('currentPosition', {
    is: false,
    then: () => yup.string().required('required'),
  }),
  currentPosition: yup.boolean(),
  descriptions: yup.array().of(yup.string()),
})

export type WorkExperienceSchema = yup.InferType<typeof workExperienceSchema>

export const profileSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().nullable(),
  location: yup.string().required('Work location is required'),
  employmentStatusId: yup.number().required('Please specify your job status'),
  desiredPosition: yup
    .string()
    .required('Please specify a position of interest'),
  yoeRangeId: yup.number().required('Years of experience is required'),
  payBasis: yup.string().required('Please specify a pay basis'),
  currentPay: yup
    .number()
    .transform((value) => (isNaN(value) ? null : parseFloat(value)))
    .nullable(),
  desiredPay: yup
    .number()
    .typeError('Desired pay is required')
    .positive('Desired pay must be a positive number')
    .required('Desired pay is required'),
})

export type ProfileSchema = yup.InferType<typeof profileSchema>

export const supportingDocumentSchema = yup.object({
  title: yup.string().required('Name is required'),
})

export type SupportingDocumentSchema = yup.InferType<
  typeof supportingDocumentSchema
>

export const resumeBuilderFileUploadSchema = yup.object({
  title: yup.string().required('Title is required'),
})

export type ResumeBuilderFileUploadSchema = yup.InferType<
  typeof resumeBuilderFileUploadSchema
>

/**
 * @deprecated This schema is deprecated and will be removed, only use it
 * to clear existing local storage keys
 */
export const jobSearchSchemaDeprecated = yup.object().shape({
  keywords: yup.string(),
  location: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable(),
  distance: yup
    .object({
      label: yup.string(),
      value: yup.number(),
    })
    .nullable(),
  daysOld: yup.number(),
  fullTime: yup.boolean(),
  inPerson: yup.boolean(),
})

/**
 * @deprecated This schema is deprecated and will be removed, only use it
 * to clear existing local storage keys
 */
export type JobSearchSchemaDeprecated = yup.InferType<
  typeof jobSearchSchemaDeprecated
>

export const jobSearchSchema = yup.object().shape({
  keywords: yup.string(),
  location: yup.string(),
  distance: yup
    .number()
    .transform((value) => parseFloat(value) || null)
    .nullable(),
})

export type JobSearchSchema = yup.InferType<typeof jobSearchSchema>

export const industrySchema = yup.object().shape({
  name: yup.string().required(),
})

export type IndustrySchema = yup.InferType<typeof industrySchema>

import React, { useCallback } from 'react'

import {
  FieldValues,
  useFormContext,
  Path,
  PathValue,
  Controller,
} from 'react-hook-form'

import { Select, SelectProps, ErrorMessage } from 'src/atoms'

export type RHFSelectProps<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>
} & Omit<SelectProps, 'value' | 'name'>

export const RHFSelect = <TFieldValues extends FieldValues = FieldValues>({
  name,
  onChange: handleChange,
  ...props
}: RHFSelectProps<TFieldValues>) => {
  const { control, setValue } = useFormContext<TFieldValues>()

  const handleClear = useCallback(() => {
    const val = '' as PathValue<TFieldValues, Path<TFieldValues>>
    setValue(name, val, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
    handleChange?.(val)
  }, [name, setValue, handleChange])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <>
            <Select
              {...field}
              {...props}
              onChange={handleChange || onChange}
              onClear={handleClear}
            />
            <ErrorMessage message={error?.message} />
          </>
        )
      }}
    />
  )
}

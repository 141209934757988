import React from 'react'

import { monthOptions } from 'src/lib/constants'

import { Select, SelectProps } from './Select'

export const SelectMonth = React.forwardRef<HTMLSelectElement, SelectProps>(
  function SelectMonth(props, ref) {
    return (
      <Select ref={ref} {...props}>
        {monthOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    )
  }
)

import clsx from 'clsx'

import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import { Field, Label, Radio, RadioGroup, RadioGroupProps } from 'src/atoms'

type YearsOfExperienceRangesQuery = {
  yearsOfExperienceRanges: {
    id: number
    range: string
  }[]
}

// * BE should return ranges sorted by ordinal in ascending order.

export const QUERY: TypedDocumentNode<YearsOfExperienceRangesQuery> = gql`
  query YearsOfExperienceRangesQuery {
    yearsOfExperienceRanges {
      id
      range
    }
  }
`

/**
 * Used to connect Radio inputs.
 */
const Line = ({ disabled }: { disabled?: boolean }) => (
  <div
    // IMPORTANT: Please make sure these colors are kept in sync with `Radio.tsx`.
    className={`w-full ${disabled ? 'bg-zinc-950/25' : 'bg-zinc-950/15'} h-[1px]`}
  />
)

// * We do not want to show anything while loading since this is a special radio input.
export const Loading = () => null

// * Removed `Empty` as it should never be used.

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  yearsOfExperienceRanges,
  ...radioGroupProps
}: CellSuccessProps<YearsOfExperienceRangesQuery> & RadioGroupProps) => {
  return (
    <RadioGroup
      {...radioGroupProps}
      // Needed because radio values come in as strings and `id` is an integer.
      setFieldValue={(n, v) => radioGroupProps.setFieldValue(n, parseInt(v))}
    >
      {/* NOTE: Important to maintain `mb-[20px] as that accounts for the absolutely positioned labels. */}
      <div className="flex items-center justify-between mb-[20px]">
        {yearsOfExperienceRanges.map((yearsOfExperienceRange, index) => {
          const idString = yearsOfExperienceRange.id.toString()
          return (
            <>
              {index !== 0 && <Line disabled={radioGroupProps.disabled} />}
              <Field
                className="flex flex-col items-center"
                key={yearsOfExperienceRange.id}
              >
                <Radio value={idString} />
                <div className="relative">
                  <Label
                    className={clsx(
                      'absolute top-[2px] translate-x-[-50%] w-max',
                      radioGroupProps.value === idString && '!font-bold'
                    )}
                  >
                    {yearsOfExperienceRange.range}
                  </Label>
                </div>
              </Field>
            </>
          )
        })}
      </div>
    </RadioGroup>
  )
}

import React from 'react'

import { FieldValues, useFormContext, Path, Controller } from 'react-hook-form'

import { Input, InputProps, ErrorMessage } from 'src/atoms'

export type RHFInputProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
} & Omit<InputProps, 'value'>

export const RHFInput = <TFieldValues extends FieldValues>({
  name,
  ...props
}: RHFInputProps<TFieldValues>) => {
  const { control } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <Input {...field} {...props} />
            <ErrorMessage message={error?.message} />
          </>
        )
      }}
    />
  )
}

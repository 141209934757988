import { cva, type VariantProps } from 'class-variance-authority'

export const reactSelectVariants = cva('', {
  variants: {
    background: {
      white: '',
      primary: '',
      transparent: '',
    },
    bordered: {
      true: '',
      false: '',
    },
    rounded: {
      true: '',
      false: '',
    },
    size: {
      sm: '',
      lg: '',
    },
  },
})

export type ReactSelectVariantProps = VariantProps<typeof reactSelectVariants>

import { Formik } from 'formik'
import * as yup from 'yup'

import { toast } from '@redwoodjs/web/toast'

import {
  Button,
  Dialog,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Input,
  Label,
  RequiredAsterisk,
  SelectFormikDeprecated,
  Text,
} from 'src/atoms'
import SalaryPrefix from 'src/components/Forms/SalaryPrefix'
import SalarySuffix from 'src/components/Forms/SalarySuffix'
import RadioYearsOfExperienceRangeCell from 'src/components/YearsOfExperienceRange/RadioYearsOfExperienceRangeCell'
import { useUpdateProfile } from 'src/hooks/mutations/useUpdateProfile'
import { payBasisOptions } from 'src/lib/constants'

import { InAppOnboardingDialogQueryProfile } from '../InAppOnboardingDialogCell'

const schema = yup.object().shape({
  currentPay: yup.number().min(0, 'Current pay must be 0 or a positive number'),
  desiredPay: yup
    .number()
    .positive('Desired pay must be a positive number')
    .required('Desired pay is required'),
  payBasis: yup.string().required('Please specify a pay basis'),
  yoeRangeId: yup.number().required('Years of experience is required'),
})

export function YearsOfExperienceAndPayDialog({
  profile,
}: {
  profile: InAppOnboardingDialogQueryProfile
}) {
  const [updateProfile, { loading }] = useUpdateProfile({
    onError: (error) => {
      toast.error(error.message)
    },
    onCompleted: () => {
      window.location.reload()
    },
  })

  return (
    <Dialog
      className="p-6"
      onClose={() => {}} // This prevents the dialog from being manually closed.
      size="2xl"
      open
    >
      <div className="flex flex-col gap-12">
        <div>
          <Text className="text-2xl" weight="bold">
            Welcome to Laborup!
          </Text>
          <Text className="text-md text-gray-900">
            The information below helps us find the right fit for you
          </Text>
        </div>
        <div>
          <Formik
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={async (values: yup.InferType<typeof schema>) => {
              updateProfile({
                variables: {
                  input: {
                    currentPay:
                      // @ts-expect-error: If the current pay is empty, it will
                      // come from the input as an empty string.
                      values.currentPay === '' ? null : values.currentPay,
                    desiredPay: values.desiredPay,
                    payBasis: values.payBasis,
                    yoeRangeId: values.yoeRangeId,
                  },
                },
              })
            }}
            initialValues={{
              // @ts-expect-error: Input expects empty string otherwise we get
              // controlled/uncontrolled errors from React.
              currentPay: profile.currentPay || '',
              // @ts-expect-error: Input expects empty string otherwise we get
              // controlled/uncontrolled errors from React.
              desiredPay: profile.desiredPay || '',
              payBasis: profile.payBasis || '',
              // @ts-expect-error: Input expects empty string otherwise we get
              // controlled/uncontrolled errors from React.
              yoeRangeId: profile.yoeRangeId || '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <FieldGroup>
                  <Fieldset className="flex flex-col gap-6">
                    <Field>
                      <div className="flex flex-col gap-2">
                        <Label>
                          Years of manufacturing experience
                          <RequiredAsterisk />
                        </Label>
                        <div>
                          <RadioYearsOfExperienceRangeCell
                            name="yoeRangeId"
                            setFieldValue={setFieldValue}
                            value={values.yoeRangeId.toString()} // Radio expects a string.
                          />
                          {errors.yoeRangeId && (
                            <ErrorMessage message={errors.yoeRangeId} />
                          )}
                        </div>
                      </div>
                    </Field>
                    <Field>
                      <div className="flex flex-col gap-2">
                        <Label>
                          Are you paid on an hourly or yearly basis?
                          <RequiredAsterisk />
                        </Label>
                        <div>
                          <SelectFormikDeprecated
                            name="payBasis"
                            placeholder="Select a pay basis"
                            setFieldValue={setFieldValue}
                            value={values.payBasis}
                            required
                          >
                            {payBasisOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </SelectFormikDeprecated>
                          {errors.payBasis && (
                            <ErrorMessage message={errors.payBasis} />
                          )}
                        </div>
                      </div>
                    </Field>
                    <Field>
                      <div className="flex flex-col gap-2">
                        <Label>Current pay</Label>
                        <div>
                          <Input
                            type="number"
                            name="currentPay"
                            placeholder={
                              !values.payBasis ? 'Select a pay basis above' : ''
                            }
                            disabled={!values.payBasis}
                            onChange={handleChange}
                            value={values.currentPay}
                            prefix={values.payBasis ? <SalaryPrefix /> : null}
                            suffix={
                              values.payBasis && (
                                <SalarySuffix payBasis={values?.payBasis} />
                              )
                            }
                          />
                          {errors.currentPay && (
                            <ErrorMessage message={errors.currentPay} />
                          )}
                        </div>
                      </div>
                    </Field>
                    <Field>
                      <div className="flex flex-col gap-2">
                        <Label>
                          Desired pay
                          <RequiredAsterisk />
                        </Label>
                        <div>
                          <Input
                            prefix={values.payBasis ? <SalaryPrefix /> : null}
                            type="number"
                            name="desiredPay"
                            placeholder={
                              !values.payBasis ? 'Select a pay basis above' : ''
                            }
                            disabled={!values.payBasis}
                            onChange={handleChange}
                            value={values.desiredPay}
                            required
                            suffix={
                              values.payBasis && (
                                <SalarySuffix payBasis={values?.payBasis} />
                              )
                            }
                          />
                          {errors.desiredPay && (
                            <ErrorMessage message={errors.desiredPay} />
                          )}
                        </div>
                      </div>
                    </Field>
                  </Fieldset>
                </FieldGroup>
                <div className="flex justify-end mt-6">
                  <Button
                    className="w-full md:w-auto"
                    disabled={!!loading}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Dialog>
  )
}

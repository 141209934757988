import { navigate, routes } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import { Button, Text } from 'src/atoms'

import EditIndustriesCell from 'src/components/Industry/EditIndustriesCell'

import { useUpdateProfile } from 'src/hooks/mutations/useUpdateProfile'

export function IndustriesStep({
  employmentStatusId,
  desiredPosition,
  isTopStartingRole,
  handleBack,
}: {
  employmentStatusId: number
  desiredPosition: string
  isTopStartingRole: boolean
  handleBack: () => void
}) {
  const [updateProfile, { loading }] = useUpdateProfile({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted() {
      window.location.reload()
      navigate(routes.profile())
    },
  })

  const handleComplete = () => {
    updateProfile({
      variables: {
        input: {
          employmentStatusId,
          desiredPosition,
        },
      },
    })
  }

  return (
    <div className="flex flex-col gap-6">
      <div>
        <Text weight="bold" className="text-2xl">
          {isTopStartingRole
            ? 'Have you worked in any specific industries?'
            : 'What industries have you worked in?'}
        </Text>
        <Text className="text-md text-gray-900">
          You can change this anytime in your profile.
        </Text>
      </div>
      <EditIndustriesCell />
      {isTopStartingRole && (
        <Text weight="medium" className="text-sm">
          Have no prior experience?{' '}
          {/* Use plain button so it can be properly inlined. */}
          <button className="font-semibold underline" onClick={handleComplete}>
            Skip this question
          </button>
        </Text>
      )}
      {/* Hack to ensure sticky buttons don't cover up content on bottom. */}
      <div className="h-12" />
      <div className="fixed bottom-0 left-0 border-t border-gray-200 flex gap-4 px-8 py-4 bg-white w-full">
        <Button className="w-full" color="white" onClick={handleBack}>
          Back
        </Button>
        <Button className="w-full" onClick={handleComplete} loading={loading}>
          Next
        </Button>
      </div>
    </div>
  )
}

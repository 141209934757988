import { useState, useRef } from 'react'

import { Skill } from '@prisma/client'
import * as formik from 'formik'
import * as yup from 'yup'

import {
  BadgeButton,
  Button,
  Field,
  FieldGroup,
  Fieldset,
  Input,
  PlusIcon,
  XMarkIcon,
} from 'src/atoms'
import RecommendedSkillsCell from 'src/components/RecommendedSkillsCell'

import { GetRecommendedSkillsInput } from 'types/index'

const schema = yup.object().shape({
  name: yup.string().required(),
})

const SkillForm = ({
  skills,
  loading,
  getRecommendedSkillsInput,
  onSave,
  onDelete,
}: {
  skills: Skill[]
  loading: boolean
  getRecommendedSkillsInput: GetRecommendedSkillsInput
  onSave: (name: string) => void
  onDelete: (id: number) => void
}) => {
  const [deletedSkills, setDeletedSkills] = useState([])

  const { Formik } = formik
  const inputRef = useRef(null)

  const handleDelete = (skill) => {
    const skillToDelete = skills.find((s) => s.name === skill.name)
    if (!skillToDelete) return

    onDelete(skillToDelete.id)

    const newDeletedSkills = Array.from(new Set([...deletedSkills, skill.name]))
    setDeletedSkills(newDeletedSkills)
  }

  const handleAddNewSkill = (skillName: string) => {
    if (skillName === '' || skills.find(({ name }) => name === skillName))
      return

    onSave(skillName)
    inputRef.current.value = ''
  }

  return (
    <Formik validationSchema={schema} initialValues={[]}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldGroup>
            <Fieldset>
              <Field className="mb-4 flex flex-row flex-nowrap items-end">
                <div className="me-2 flex flex-1 flex-col">
                  <Input
                    ref={inputRef}
                    placeholder="Tools, machines, and skills"
                    className=""
                    onKeyUp={(e) => {
                      if (e.key === 'Enter' || e.keyCode === 13) {
                        handleAddNewSkill(e.target.value)
                      }
                    }}
                  />
                </div>
                <Button
                  disabled={loading}
                  size="lg"
                  onClick={() => handleAddNewSkill(inputRef.current.value)}
                >
                  <PlusIcon />
                  Add
                </Button>
              </Field>
              <div className="d-flex mt-n2 ms-n2 flex-wrap">
                {skills.map((skill, indx) => (
                  <BadgeButton
                    key={indx}
                    className="fs-sm mb-1 me-1 ms-2 mt-2"
                    onClick={() => {
                      handleDelete(skill)
                    }}
                  >
                    {skill.name}
                    <XMarkIcon className="h-4 w-4 text-gray-950" />
                  </BadgeButton>
                ))}
              </div>
            </Fieldset>
            <RecommendedSkillsCell
              existingSkills={skills}
              deletedSkills={deletedSkills}
              handleAddNewSkill={handleAddNewSkill}
              loading={loading}
              // ? Why isn't TS catching this as required?
              getRecommendedSkillsInput={getRecommendedSkillsInput}
            />
          </FieldGroup>
        </form>
      )}
    </Formik>
  )
}

export default SkillForm

import {
  type CellFailureProps,
  type CellSuccessProps,
  type TypedDocumentNode,
} from '@redwoodjs/web'

import { DefaultOnboardingDialog } from './DefaultOnboardingDialog/DefaultOnboardingDialog'
import { YearsOfExperienceAndPayDialog } from './YearsOfExperienceAndPayDialog/YearsOfExperienceAndPayDialog'

export type InAppOnboardingDialogQueryProfile = {
  id: number
  employmentStatusId: number | null
  desiredPosition: string | null
  currentPay: number | null
  desiredPay: number | null
  payBasis: string | null
  yoeRangeId: number | null
  source: string | null
}

type InAppOnboardingDialogQuery = {
  profile: InAppOnboardingDialogQueryProfile
}

type InAppOnboardingDialogQueryVariables = {
  id: number
}

export const QUERY: TypedDocumentNode<
  InAppOnboardingDialogQuery,
  InAppOnboardingDialogQueryVariables
> = gql`
  query FindInAppOnboardingDialogQuery {
    profile {
      id
      employmentStatusId
      desiredPosition
      currentPay
      desiredPay
      payBasis
      yoeRangeId
      source
    }
  }
`

// We do not want to show anything while loading.
export const Loading = () => null

// NOTE: Removed `Empty` as it should never be used.

// We do not want to show anything on failure.
// TODO: The error should be logged to Sentry.
export const Failure = ({
  error: _error,
}: CellFailureProps<InAppOnboardingDialogQueryVariables>) => null

export const Success = ({
  profile,
}: CellSuccessProps<
  InAppOnboardingDialogQuery,
  InAppOnboardingDialogQueryVariables
>) => {
  // If the user came through giving an endorsement,
  // we don't want to show any onboarding modals.
  if (profile.source === 'Endorsement') {
    return null
  }

  // If the user does not have an employment status or desired position,
  // we assume that they have entered through the default variant and
  // therefore we take them through the default onboarding modal.
  //
  // There is a possibility that the user has some info that is collected
  // during the default onboarding process but this is not expected and
  // for simplicity we will assume that they are missing all of it.
  if (!profile.employmentStatusId || !profile.desiredPosition) {
    return <DefaultOnboardingDialog />
  } else if (!profile.desiredPay || !profile.payBasis || !profile.yoeRangeId) {
    return <YearsOfExperienceAndPayDialog profile={profile} />
  }

  return null
}

import { forwardRef } from 'react'

import {
  Input as HeadlessInput,
  type InputProps as HeadlessInputProps,
} from '@headlessui/react'
import { clsx } from 'clsx'

export function InputGroup({
  children,
  className,
}: React.ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      data-slot="control"
      className={clsx(
        'relative isolate block',
        '[&_input]:has-[[data-slot=icon]:first-child]:pl-10 [&_input]:has-[[data-slot=icon]:last-child]:pr-10 sm:[&_input]:has-[[data-slot=icon]:first-child]:pl-8 sm:[&_input]:has-[[data-slot=icon]:last-child]:pr-8',
        '[&>[data-slot=icon]]:pointer-events-none [&>[data-slot=icon]]:absolute [&>[data-slot=icon]]:top-3 [&>[data-slot=icon]]:z-10 [&>[data-slot=icon]]:size-5 sm:[&>[data-slot=icon]]:top-2.5 sm:[&>[data-slot=icon]]:size-4',
        '[&>[data-slot=icon]:first-child]:left-3 sm:[&>[data-slot=icon]:first-child]:left-2.5 [&>[data-slot=icon]:last-child]:right-3 sm:[&>[data-slot=icon]:last-child]:right-2.5',
        '[&>[data-slot=icon]]:text-gray-900',
        className
      )}
    >
      {children}
    </span>
  )
}

const dateTypes = ['date', 'datetime-local', 'month', 'time', 'week']
type DateType = (typeof dateTypes)[number]

export type InputProps = {
  type?:
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'url'
    | DateType
  inputClassName?: string
  bordered?: boolean
  prefix?: React.ReactNode
  suffix?: React.ReactNode
} & HeadlessInputProps

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, inputClassName, bordered = true, prefix, suffix, ...props },
  ref
) {
  return (
    <span
      data-slot="control"
      className={clsx([
        className,
        'relative flex w-full rounded-lg bg-white min-h-9',
        'has-[[data-disabled]]:bg-gray-200',
        'before:has-[[data-disabled]]:shadow-none before:pointer-events-none',
        bordered && 'before:shadow before:absolute before:rounded-lg',
        bordered && 'before:has-[[data-invalid]]:shadow-red-500/10',
        'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg  after:ring-transparent',
        bordered &&
          'sm:after:focus-within:ring-2 sm:after:focus-within:ring-primary-700',
        bordered && 'border border-gray-500',
        bordered &&
          'has-[[data-invalid]]:border-red-700 has-[[data-invalid]]:data-[hover]:border-red-800',
        bordered && 'has-[[data-disabled]]:border-gray-400',
      ])}
    >
      {prefix}
      <HeadlessInput
        ref={ref}
        className={clsx([
          'relative flex w-full appearance-none items-center rounded-lg bg-transparent px-3 text-md font-medium text-gray-950 focus:outline-none',
          props.type &&
            dateTypes.includes(props.type) && [
              '[&::-webkit-datetime-edit-fields-wrapper]:p-0',
              '[&::-webkit-date-and-time-value]:min-h-[1.5em]',
              '[&::-webkit-datetime-edit]:inline-flex',
              '[&::-webkit-datetime-edit]:p-0',
              '[&::-webkit-datetime-edit-year-field]:p-0',
              '[&::-webkit-datetime-edit-month-field]:p-0',
              '[&::-webkit-datetime-edit-day-field]:p-0',
              '[&::-webkit-datetime-edit-hour-field]:p-0',
              '[&::-webkit-datetime-edit-minute-field]:p-0',
              '[&::-webkit-datetime-edit-second-field]:p-0',
              '[&::-webkit-datetime-edit-millisecond-field]:p-0',
              '[&::-webkit-datetime-edit-meridiem-field]:p-0',
            ],
          'disabled:cursor-not-allowed disabled:text-gray-700 disabled:bg-transparent',
          'placeholder:text-gray-700 disabled:placeholder:text-gray-600',

          inputClassName,
        ])}
        {...props}
      />
      {suffix}
    </span>
  )
})

import { Text } from 'src/atoms'

export default function SalarySuffix({ payBasis }: { payBasis: string }) {
  return (
    <div>
      <Text
        weight="medium"
        className="flex px-2 text-gray-950 h-full items-center text-md rounded-tr-lg rounded-br-lg border-l border-gray-500 text-nowrap whitespace-nowrap"
      >
        {payBasis === 'Hourly' ? 'per hour' : 'per year'}
      </Text>
    </div>
  )
}

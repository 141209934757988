import { useMutation } from '@redwoodjs/web'

const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfileMutation($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
    }
  }
`

export function useUpdateProfile<
  TData = any,
  TVariables = GraphQLOperationVariables,
>(options?: GraphQLMutationHookOptions<TData, TVariables>) {
  return useMutation(UPDATE_PROFILE_MUTATION, options)
}

import { BoltIcon } from '@heroicons/react/24/solid'
import { Skill } from '@prisma/client'

import { BadgeColor, BadgeButton, PlusIcon, Text } from 'src/atoms'
import { Shimmer } from 'src/components/Shimmer/Shimmer'

import { GetRecommendedSkillsInput } from 'types/index'

export const QUERY = gql`
  query GET_SKILL_RECCOMENDATIONS(
    $variant: String!
    $desiredPosition: String
    $shouldOnlyRecommendSoftSkills: Boolean
  ) {
    choices: getRecommendedSkills(
      variant: $variant
      desiredPosition: $desiredPosition
      shouldOnlyRecommendSoftSkills: $shouldOnlyRecommendSoftSkills
    ) {
      choices
    }
  }
`

export const beforeQuery = ({
  getRecommendedSkillsInput,
}: {
  getRecommendedSkillsInput: GetRecommendedSkillsInput
}) => {
  return {
    variables: {
      ...getRecommendedSkillsInput,
    },
  }
}

export const Loading = () => <Shimmer className="w-full h-32 md:h-16" />
export const Empty = () => (
  <Text weight="semibold" className="text-sm text-gray-700">
    Empty
  </Text>
)

export const Failure = ({ error }) => (
  <Text weight="semibold" className="text-sm text-red-800">
    Error: {error?.message}
  </Text>
)

export const Success = ({
  choices,
  handleAddNewSkill,
  deletedSkills,
  existingSkills,
  loading,
}: {
  choices?: { choices: string[] }
  handleAddNewSkill: (skillName: string) => void
  deletedSkills: string[]
  existingSkills: Skill[]
  loading: boolean
  getRecommendedSkillsInput: GetRecommendedSkillsInput
}) => {
  const existingSkillsNames = existingSkills.map((skill) => skill.name)

  let recommendedSkills = [
    ...(choices?.choices || []),
    ...(deletedSkills || []),
  ]

  recommendedSkills = Array.from(new Set(recommendedSkills))
  recommendedSkills =
    recommendedSkills
      ?.filter?.((skill) => !existingSkillsNames.includes(skill))
      ?.filter?.((skill) => skill.split(' ').length <= 3)
      ?.sort?.() || []

  // TODO: A nice future improvement would be to generate more
  // skills on the fly if all of the recommended skills have
  // been added.
  return (
    <>
      {recommendedSkills.length > 0 && (
        <div className="rounded border border-gray-400 bg-gray-50 px-4 py-3 md:px-6 md:py-4">
          <div className="mb-2.5 flex items-center">
            <Text weight="semibold" className="text-md text-gray-950">
              <span className="flex items-center">
                <BoltIcon className="text-primary-700 me-1.5 h-5 w-5 shrink-0" />
                Laborup Suggestions
              </span>
            </Text>
          </div>
          <div className="d-flex flex-wrap gap-3">
            {recommendedSkills.map((skill, indx) => (
              <BadgeButton
                key={indx}
                color={BadgeColor.gray}
                disabled={loading}
                onClick={() => handleAddNewSkill(skill)}
              >
                {skill}
                <PlusIcon className="h-4 w-4 text-gray-950" />
              </BadgeButton>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

import { Spinner, Container } from 'react-bootstrap'

import { Skill } from '@prisma/client'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Text } from 'src/atoms'
import { DELETE_SKILL_MUTATION } from 'src/components/Skill/Skill'
import SkillForm from 'src/components/Skill/SkillForm'

import { GetRecommendedSkillsInput } from 'types/index'

export const QUERY = gql`
  query FindSkills {
    skills {
      id
      name
      createdAt
      updatedAt
      userId
    }
  }
`

const CREATE_SKILL_MUTATION = gql`
  mutation CreateSkillMutation($input: CreateSkillInput!) {
    createSkill(input: $input) {
      id
    }
  }
`

export const Loading = () => (
  <Container style={{ minHeight: '50vh' }}>
    <Spinner animation="border" role="status">
      <Text weight="semibold" className="text-sm text-gray-700">
        Loading...
      </Text>
    </Spinner>
  </Container>
)

export const Failure = ({ error }) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  getRecommendedSkillsInput,
  skills,
}: {
  getRecommendedSkillsInput: GetRecommendedSkillsInput
  skills: Skill[]
}) => {
  const [createSkill, { loading: createSkillLoading }] = useMutation(
    CREATE_SKILL_MUTATION,
    {
      // * We purposely don't show a success toast here because skills can be added
      // * in rapid succession from the suggestions and causes the toasts to take up
      // * a large amount of the screen.

      onError: (error) => {
        toast.error(error.message)
      },
      refetchQueries: [{ query: QUERY }],
      awaitRefetchQueries: true,
    }
  )

  const [deleteSkill, { loading: deleteSkillLoading }] = useMutation(
    DELETE_SKILL_MUTATION,
    {
      onCompleted: () => {
        toast.success('Skill deleted')
      },
      onError: (error) => {
        toast.error(error.message)
      },
      refetchQueries: [{ query: QUERY }],
      awaitRefetchQueries: true,
    }
  )

  const onSave = (name: string) => {
    createSkill({ variables: { input: { name } } })
  }

  const onDelete = (id: number) => {
    deleteSkill({ variables: { id } })
  }

  return (
    <SkillForm
      skills={skills}
      onSave={onSave}
      onDelete={onDelete}
      loading={createSkillLoading || deleteSkillLoading}
      getRecommendedSkillsInput={getRecommendedSkillsInput}
    />
  )
}

import { Button, Text } from 'src/atoms'

import EditSkillsCell from 'src/components/Skill/EditSkillsCell'

export function SkillsStep({
  desiredPosition,
  shouldOnlyRecommendSoftSkills,
  handleBack,
  handleNext,
}: {
  desiredPosition: string
  shouldOnlyRecommendSoftSkills: boolean
  handleBack: () => void
  handleNext: () => void
}) {
  return (
    <div className="flex flex-col gap-6">
      <div>
        <Text weight="bold" className="text-2xl">
          Add your skills as a {desiredPosition}
        </Text>
        <Text className="text-md text-gray-900">
          You can change this anytime in your profile.
        </Text>
      </div>
      <EditSkillsCell
        getRecommendedSkillsInput={{
          variant: 'onboarding',
          desiredPosition,
          shouldOnlyRecommendSoftSkills,
        }}
      />
      {/* Hack to ensure sticky buttons don't cover up content on bottom. */}
      <div className="h-12" />
      <div className="fixed bottom-0 left-0 border-t border-gray-200 flex gap-4 px-8 py-4 bg-white w-full">
        <Button color='white' className="w-full" onClick={handleBack}>
          Back
        </Button>
        <Button className="w-full" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  )
}

import type React from 'react'
import { Fragment } from 'react'

import {
  Description as HeadlessDescription,
  Dialog as HeadlessDialog,
  DialogPanel as HeadlessDialogPanel,
  DialogTitle as HeadlessDialogTitle,
  Transition as HeadlessTransition,
  TransitionChild as HeadlessTransitionChild,
  type DialogProps as HeadlessDialogProps,
  CloseButton as HeadlessCloseButton,
} from '@headlessui/react'
import clsx from 'clsx'

import { XMarkIcon, Text } from 'src/atoms'

import './Dialog.css'

const sizes = {
  xs: 'sm:max-w-xs',
  sm: 'sm:max-w-sm',
  md: 'sm:max-w-md',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  '4xl': 'sm:max-w-4xl',
  '5xl': 'sm:max-w-5xl',
  full: 'w-full',
  fullScreen: 'w-full',
}

export function Dialog({
  open,
  onClose,
  size = 'lg',
  className,
  children,
  ...props
}: {
  size?: keyof typeof sizes
  children: React.ReactNode
} & HeadlessDialogProps) {
  // TODO: Use cva instead?
  const isNotFullScreen = size !== 'fullScreen'

  return (
    <HeadlessTransition appear as={Fragment} show={open} {...props}>
      <HeadlessDialog onClose={onClose}>
        <HeadlessTransitionChild
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 flex w-screen justify-center overflow-y-auto overflow-x-hidden bg-blackAlpha-600 px-2 py-2 focus:outline-0 sm:px-6 sm:py-8 lg:px-8 lg:py-16" />
        </HeadlessTransitionChild>

        <div
          className={clsx(
            'fixed inset-0 w-screen overflow-y-auto overflow-x-hidden',
            isNotFullScreen && 'pt-6 sm:pt-0'
          )}
        >
          <div
            className={clsx(
              'min-h-full grid-rows-[1fr_auto] justify-items-center sm:grid-rows-[1fr_auto_3fr]',
              isNotFullScreen ? 'grid sm:p-4' : 'flex'
            )}
          >
            <HeadlessTransitionChild
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 translate-y-12 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-12 sm:translate-y-0"
            >
              <HeadlessDialogPanel
                className={clsx(
                  className,
                  sizes[size],
                  'row-start-2 w-full min-w-0 bg-white p-4 [--gutter:theme(spacing.8)] dark:ring-white/10 forced-colors:outline',
                  isNotFullScreen &&
                    'rounded-t-3xl shadow-lg sm:mb-auto sm:rounded-2xl'
                )}
              >
                {children}
              </HeadlessDialogPanel>
            </HeadlessTransitionChild>
          </div>
        </div>
      </HeadlessDialog>
    </HeadlessTransition>
  )
}

export function DialogTitle({
  className,
  showCloseIcon = true,
  onClose,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & {
  showCloseIcon?: boolean
  onClose?: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <div className="flex w-full relative">
      <HeadlessDialogTitle
        {...props}
        className={clsx(
          className,
          // 'text-balance text-lg/6 font-semibold text-zinc-950 sm:text-base/6 dark:text-white'
          'grow text-balance text-lg/6 font-semibold text-zinc-950 sm:text-base/6'
        )}
      />
      {showCloseIcon && (
        <HeadlessCloseButton
          onClick={onClose}
          className="h-fit absolute right-0 top-0 z-10"
        >
          <XMarkIcon className="size-5" />
        </HeadlessCloseButton>
      )}
    </div>
  )
}

export function DialogDescription({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <HeadlessDescription
      as={Text}
      {...props}
      className={clsx(className, 'mt-2 text-pretty')}
    />
  )
}

export function DialogBody({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return <div {...props} className={clsx(className, 'mt-4 md:mt-6')} />
}

export function DialogActions({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      {...props}
      className={clsx(
        className,
        'mt-8 flex flex-col-reverse items-center justify-end gap-3 *:w-full sm:flex-row sm:*:w-auto'
      )}
    />
  )
}

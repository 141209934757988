import { forwardRef } from 'react'

import { monthOptions } from 'src/lib/constants'

import {
  SelectFormikDeprecated,
  SelectFormikDeprecatedProps,
} from './SelectFormikDeprecated'

/**
 * @deprecated This component is deprecated and will be removed, prefer to use
 * Select from atoms or react hook form folder instead
 */
export const SelectMonthDeprecated = forwardRef<
  HTMLSelectElement,
  SelectFormikDeprecatedProps
>(function SelectMonth(props, ref) {
  return (
    <SelectFormikDeprecated ref={ref} {...props}>
      {monthOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectFormikDeprecated>
  )
})

import React from 'react'

import { FieldValues, useFormContext, Path, Controller } from 'react-hook-form'

import {
  AutocompleteInput,
  AutocompleteInputProps,
  ErrorMessage,
} from 'src/atoms'

export type RHFAutocompleteInputProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  onChange?: (value?: string) => void
} & Omit<AutocompleteInputProps, 'value' | 'onChange'>

export const RHFAutocompleteInput = <TFieldValues extends FieldValues>({
  name,
  ...props
}: RHFAutocompleteInputProps<TFieldValues>) => {
  const { control } = useFormContext<TFieldValues>()

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <AutocompleteInput {...field} {...props} />
              <ErrorMessage message={error?.message} />
            </>
          )
        }}
      />
    </>
  )
}

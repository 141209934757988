import React, { useCallback } from 'react'

import clsx from 'clsx'

type CustomProps = {
  label?: React.ReactNode
  labelClassName?: string
}

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & CustomProps

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  CustomProps

export type BulletListTextareaProps = {
  value?: string[]
  onChange: (value: string[]) => void
} & Omit<TextareaProps, 'onChange' | 'value'>

const prefix = '• '
const getTextareaValueFromBulletListStrings = (bulletListStrings: string[]) => {
  if (bulletListStrings.length === 0) {
    return prefix
  }

  let value = ''
  for (let i = 0; i < bulletListStrings.length; i++) {
    const string = bulletListStrings[i]
    const isLastItem = i === bulletListStrings.length - 1
    value += `${prefix}${string}${isLastItem ? '' : '\r\n'}`
  }
  return value
}

const getBulletListStringsFromTextareaValue = (
  textareaValue: string
): string[] => {
  const textareaValueWithNormalizedLineBreak = normalizeLineBreak(textareaValue)

  const strings = getStringsByLineBreak(textareaValueWithNormalizedLineBreak)

  const nonEmptyStrings = strings.filter((s) => s !== '•')

  const newStrings: string[] = []
  for (const string of nonEmptyStrings) {
    if (string.startsWith('• ')) {
      newStrings.push(string.slice(2))
    } else if (string.startsWith('•')) {
      // Handle the special case when user wants to delete the bullet point, in which case
      // we combine it with the previous line if previous line exists
      const lastItemIdx = newStrings.length - 1
      if (lastItemIdx >= 0) {
        const lastItem = newStrings[lastItemIdx]
        newStrings[lastItemIdx] = `${lastItem}${string.slice(1)}`
      } else {
        newStrings.push(string.slice(1))
      }
    } else {
      newStrings.push(string)
    }
  }
  return newStrings
}

/**
 * Label is preferable than having input as a sibling since it makes clicking
 * label auto focus input children
 */
export const Label = ({ className, children, label, ...props }: LabelProps) => (
  <label
    {...props}
    className={clsx(className, 'text-gray-950 font-semibold text-md')}
  >
    {label}
    {children}
  </label>
)

export const Textarea = React.forwardRef(function Textarea(
  { label, className, labelClassName, ...props }: TextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <Label label={label} className={labelClassName}>
      <textarea
        {...props}
        ref={ref}
        rows={10}
        className={clsx(
          className,
          'px-3 py-2 block w-full rounded-lg font-md text-md',
          'border border-gray-500 min-h-20 flex-grow resize-none overflow-y-auto shadow-sm focus:outline focus:outline-2 focus:outline-primary-700'
        )}
      />
    </Label>
  )
})

const NORMALIZED_LINE_BREAK = '\n'
/**
 * Normalize line breaks to be \n since different OS uses different line break
 *    Windows -> \r\n (CRLF)
 *    Unix    -> \n (LF)
 *    Mac     -> \n (LF), or \r (CR) for earlier versions
 */
const normalizeLineBreak = (str: string) =>
  str.replace(/\r?\n/g, NORMALIZED_LINE_BREAK)
const getStringsByLineBreak = (str: string) => str.split(NORMALIZED_LINE_BREAK)

export const BulletListTextarea = React.forwardRef(function BulletListTextarea(
  { value = [], onChange, ...props }: BulletListTextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  const textareaValue = getTextareaValueFromBulletListStrings(value)

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> =
    useCallback(
      (e) => {
        onChange(getBulletListStringsFromTextareaValue(e.target.value))
      },
      [onChange]
    )
  return (
    <Textarea
      {...props}
      ref={ref}
      value={textareaValue}
      onChange={handleChange}
    />
  )
})

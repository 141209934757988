import { JobSearchSchema } from 'src/schemas'

export const PX_PER_PT = 4 / 3

// Reference: https://www.prepressure.com/library/paper-size/letter
// Letter size is commonly used in US & Canada, while A4 is the standard for rest of world.
export const LETTER_WIDTH_PT = 612
const LETTER_HEIGHT_PT = 792
export const LETTER_WIDTH_PX = LETTER_WIDTH_PT * PX_PER_PT
export const LETTER_HEIGHT_PX = LETTER_HEIGHT_PT * PX_PER_PT

// Reference: https://www.prepressure.com/library/paper-size/din-a4
export const A4_WIDTH_PT = 595
const A4_HEIGHT_PT = 842
export const A4_WIDTH_PX = A4_WIDTH_PT * PX_PER_PT
export const A4_HEIGHT_PX = A4_HEIGHT_PT * PX_PER_PT

export const DEBUG_RESUME_PDF_FLAG: true | undefined = undefined

// * IMPORTANT: This should be kept in sync with the roles on the admin side here:
// * https://github.com/Laborup/angkor/blob/main/src/utils/constants.ts#L6
export const jobTitles = [
  'Machinist',
  'CNC operator',
  'CNC machinist',
  'CNC programmer',
  'Welder',
  'Fabricator',
  'MIG welder',
  'TIG welder',
  'Stick welder',
  'Flux-cored arc welder',
  'Underwater welder',
  'Pipeline welder',
  'Fabrication welder',
  'Structural steel welder',
  'Machine operator',
  'Line operator',
  'Production supervisor',
  'Assembly technician',
  'Quality control inspector',
  'Quality technician',
  'Maintenance technician',
  'Production technician',
  'Process engineer',
  'Manufacturing engineer',
  'Industrial engineer',
  'Mechanical engineer',
  'Electrical engineer',
  'Design engineer',
  'Welding engineer',
  'Structural engineer',
  'Automation engineer',
  'Robotics engineer',
  'Quality engineer',
  'Materials engineer',
  'Product engineer',
  'Aerospace engineer',
  'Aircraft technician',
  'Avionics technician',
  'Aerospace systems engineer',
  'Flight test engineer',
  'Satellite engineer',
  'Propulsion engineer',
  'Aerospace quality inspector',
  'Automotive engineer',
  'Automotive technician',
  'Automotive designer',
  'Vehicle systems engineer',
  'Powertrain engineer',
  'Chassis engineer',
  'Automotive quality inspector',
  'Biomedical engineer',
  'Environmental engineer',
  'Chemical engineer',
  'Metallurgical engineer',
  'Systems engineer',
  'Electrical systems engineer',
  'Mechanical systems engineer',
  'Tool and die maker',
  'Production planner',
  'Materials manager',
  'Logistics coordinator',
  'Supply chain analyst',
  'Robotics technician',
  'Paint technician',
  'Fabrication specialist',
  'Plant manager',
  'Safety coordinator',
  'Assembler',
  'Forklift operator',
  'Press operator',
  'Paint sprayer',
  'Quality inspector',
  'Metal fabricator',
  'Foundry worker',
  'Stamping operator',
  'Molding technician',
  'Body shop worker',
  'Die caster',
  'Rigging technician',
  'Sheet metal worker',
  'Pipefitter',
  'Lathe operator',
  'Grinding operator',
  'Extrusion operator',
  'Welding technician',
  'Electrical assembler',
  'Maintenance mechanic',
  'Tool grinder',
  'Heat treat operator',
  'Inventory clerk',
  'Shipping and receiving clerk',
  'Packaging worker',
  'Scaffold builder',
  'Utility worker',
  'Production assembler',
  'Test technician',
  'Overhaul mechanic',
  'Composite technician',
  'Parts inspector',
  'Finishing worker',
  'Drill press operator',
  'Setup technician',
  'Solderer',
  'Millwright',
  'Plating operator',
  'Turning operator',
  'EDM operator',
  'Precision grinder',
  'Milling machine operator',
  'Bore mill operator',
  'Swiss-style lathe operator',
  'Tool room attendant',
  'Jig bore operator',
  'Gear machinist',
  'CNC lathe machinist',
  'Multi-axis machinist',
  'Waterjet operator',
  'Laser machining operator',
  'Robot cell operator',
  'Quality assurance coordinator',
  'Industrial painter',
  'Safety inspector',
  'Metallurgical technician',
  'CAD technician',
  'CAM programmer',
  'Fixture builder',
  'Material handler',
  'Process control technician',
  'Production coordinator',
  'Control system technician',
  'Fabric cutter',
  'Seamstress/tailor',
  'Pneumatic systems technician',
  'Hydraulic systems technician',
  'Prototype builder',
  'Quality systems engineer',
  'Lean manufacturing engineer',
  'Production control analyst',
  'Inventory control specialist',
  'Supply chain planner',
  'Logistics analyst',
  'Material planner',
  'Distribution coordinator',
  'Fleet manager',
  'Maintenance planner',
  'Reliability engineer',
  'Operations manager',
  'Project manager',
  'Quality manager',
  'R&D engineer',
  'Systems analyst',
  'Network engineer',
]

export const jobTitleOptions = jobTitles.map((title) => ({
  label: title,
  value: title,
}))

export const professionOptions = [
  { label: 'All Professions', value: null },
  { label: 'CNC Machinist', value: 'CNC Machinist' },
  { label: 'CNC Programmer', value: 'CNC Programmer' },
  { label: 'Machinist', value: 'Machinist' },
  { label: 'Mechanic', value: 'Mechanic' },
  { label: 'Boilermaker', value: 'Boilermaker' },
  { label: 'Pipefitter', value: 'Pipefitter' },
  { label: 'Plumber', value: 'Plumber' },
  { label: 'Welder', value: 'Welder' },
  { label: 'Fabricator', value: 'Fabricator' },
  { label: 'Maintenance Technician', value: 'Maintenance Technician' },
  { label: 'HVAC Technician', value: 'HVAC Technician' },
  {
    label: 'Skilled Construction Worker',
    value: 'Skilled Construction Worker',
  },
  { label: 'CAD Designer', value: 'Cad Designer' },
  { label: 'CAD Drafter', value: 'CAD Drafter' },
  { label: 'Machine Operator', value: 'Machine Operator' },
  { label: 'Mechatronics Technician', value: 'Mechatronics Technician' },
  { label: 'Automotive Technician', value: 'Automotive Technician' },
  { label: 'Industrial Electrician', value: 'Industrial Electrician' },
  { label: 'Commercial Truck Driver', value: 'Commercial Truck Driver' },
]

const jobStatuses = [
  'Actively seeking employment',
  'Open to new opportunities',
  'Currently employed',
]

export const jobStatusOptions = jobStatuses.map((status) => ({
  label: status,
  value: status,
}))

// * IMPORTANT: This should be kept in sync with the sources on the admin side here
// * with the exception of 'Admin Onboard' and 'Google Search Ad':
// * https://github.com/Laborup/angkor/blob/main/src/utils/constants.ts#L190
export const googleSearchAdSource = 'Google Search Ad' // Special valid source that we don't expose to users.
export const adminOnlySources = ['Admin Onboard', googleSearchAdSource] // Valid sources set on the admin side that we don't expose to users.
const sources = [
  'Social Media',
  'Billboard',
  'Flyer / Mail',
  'Job Board',
  'Referral / Friend',
  'Google Search',
  'Event',
  'Other',
]

export const sourceOptions = sources.map((source) => ({
  label: source,
  value: source,
}))

export const payBasis = ['Hourly', 'Yearly']

export const payBasisOptions = payBasis.map((basis) => ({
  label: basis,
  value: basis,
}))

export const courseFormatOptions = [
  { label: 'Online and In-person', value: null },
  { label: 'In-person', value: 'In-person' },
  { label: 'Online', value: 'Online' },
  { label: 'Hybrid', value: 'Hybrid' },
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const monthOptions = months.map((month) => ({
  label: month,
  value: month,
}))

/**
 * Used to store the job redirect path in session storage for use after signup.
 */
export const JOB_REDIRECT_PATH_SESSION_STORAGE_KEY = 'jobRedirectPath'

export const distanceOptions: { label: string; value: number }[] = [
  { label: '10 miles', value: 10 },
  { label: '20 miles', value: 20 },
  { label: '30 miles', value: 30 },
  { label: '40 miles', value: 40 },
  { label: '50+ miles', value: 200 },
]

export const defaultJobSearchQuery: JobSearchSchema = {
  keywords: '',
  location: '',
  distance: 30,
}

export type RecommendedIndustry = {
  id: number
  name: string
  added: boolean
}

export const initialRecommendedIndustries: RecommendedIndustry[] = [
  { id: 1, name: 'Manufacturing', added: false },
  { id: 2, name: 'Automotive', added: false },
  { id: 3, name: 'Aerospace', added: false },
  { id: 4, name: 'Medical Devices', added: false },
  { id: 5, name: 'Chemical Manufacturing', added: false },
  { id: 6, name: 'Utilities', added: false },
]
